import "./utils/sentry"
import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import * as serviceWorker from "./registerServiceWorker"
import MainRouter from "./containers/MainRouter"
import MaintenanceError from "./components/pages/MaintenanceError"
import { logErrorMessage } from "./utils/errors"

const root = createRoot(document.getElementById("root"))
if (
  process.env.REACT_APP_ENV !== "production" &&
  window.location.hostname === "coastlineacademy"
) {
  logErrorMessage("Attempting to run non-production build on production")
  root.render(<MaintenanceError />)
} else {
  root.render(
    <BrowserRouter>
      <MainRouter />
    </BrowserRouter>,
  )
}

serviceWorker.unregister()
