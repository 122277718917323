import * as Sentry from "@sentry/react"

if (process.env.REACT_APP_ENV === "production") {
  // Ignores are per here:
  // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
  // iOS only when someone seems to navigate away from the page when a fetch
  // request is in progress, which is fine.
  Sentry.init({
    dsn: "https://e83dc5d4b1f743c59a6485ffd0d06367@o401335.ingest.sentry.io/5260776",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    ignoreErrors: [
      "AbortError: The user aborted a request",
      "Fetch is aborted",
      "TypeError: Failed to fetch",
      "Failed to fetch",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Cancelled",
      "TypeError: cancelled",
      "Uncaught in availability search: cancelled",
      "Non-Error promise rejection captured with value: cancelled",
      "Error: UnknownError",
      "ChunkLoadError",
      "Network Error",
      // Random plugins/extensions
      "top.GLOBALS",
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      "originalCreateNotification",
      "canvas.contentDocument",
      "MyApp_RemoveAllHighlights",
      "http://tt.epicplay.com",
      "Can't find variable: ZiteReader",
      "jigsaw is not defined",
      "ComboSearch is not defined",
      "http://loading.retry.widdit.com/",
      "atomicFindClose",
      // Facebook borked
      "fb_xd_fragment",
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      "bmi_SafeAddOnload",
      "EBCallBackMessageReceived",
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      "conduitPage",
      "ResizeObserver",
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    integrations: [], // replay lazy-loaded below
    replaysSessionSampleRate: 0.1, // recommended in production
    replaysOnErrorSampleRate: 1,
  })
} else {
  // Log to a different Sentry project in dev.
  Sentry.init({
    dsn: "https://9b14589522424049ba61d6705e372b36@o401335.ingest.sentry.io/6178667",
  })
}

// eslint-disable-next-line import/no-extraneous-dependencies
import("@sentry/react").then((lazyLoadedSentry) => {
  Sentry.addIntegration(lazyLoadedSentry.replayIntegration())
})
